<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="`/`">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item active> View Direct Sales </b-breadcrumb-item>
    </b-breadcrumb>
    <br />
    <!-- <b-button>idle interval {{ IDLE_TIMEOUT - idleSecondsCounter }} </b-button> -->
    <b-card title="View Direct Sales Detail">
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="1">
            <b-button size="md" variant="primary" :to="{ name: 'direct-sales' }"
              >Back</b-button
            >
          </b-col>
        </b-row>
        <b-row>
            <b-col cols="2"><strong>Job Order</strong></b-col>
            <b-col>: {{ form.orderNumber }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Type</strong></b-col>
          <b-col>: {{ form.props.type }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><strong>Status</strong></b-col>
          <b-col>: {{ form.status }}</b-col>
        </b-row>
        <b-row style="margin-top:10px">
          <b-col cols="12">
            <b-table
              head-variant="dark"
              :fields="fieldsTable"
              :items="form.dataItem"
              small
              responsive
            >
            <template v-slot:cell(quantity)="row">
              <b-input
                :disabled="row.item.pickList.props.statusNo !== 0"
                v-model="row.item.quantity"
                type="number"
              ></b-input>
            </template>
            </b-table>
        </b-col>
    </b-row>
</b-col>
<div class="float-right" v-if="form.status === 'Approved' && form.props.type === 'Direct Sale' && form.item[0].pickList.props.statusNo == 0">
    <b-button
      variant="success"
      @click ="toPacking(form.dataItem)"
      >To Packing</b-button
    >
</div>
<div class="float-right" v-if="form.item[0].pickList.props.statusNo == 55 && form.props.status == 35">
    <b-button
      variant="success"
      @click ="jobEnd"
      >Job End</b-button
    >
</div>
</b-card>
<!-- Modal to confirm override quantity -->
<b-modal
  id="promptView"
  ref="modal"
  size="lg"
  v-model="confirmationModal"
  title="Quantity Confirmation"
  hide-footer
  no-close-on-backdrop
  no-close-on-esc
  hide-close-button
  hide-header-close
>
  <b-col cols="12">
    <b-row>
      <p>
        The quantity entered is not according to picklist.
      </p>
      <b-col cols="12" style="margin-bottom: 15px">
        <b-button
          @click="reset()"
          variant="danger"
          class="float-right"
          style="margin-left: 5px"
          >Reset Quantity as per Picklist</b-button
        >
        <b-button
          @click="confirm()"
          variant="success"
          class="float-right"
          style="margin-right: 7px"
          >Confirm and override</b-button
        >
      </b-col>
    </b-row>
  </b-col>
</b-modal>
<!-- Modal for showing Packing Details-->
<b-modal
  id="promptDetail"
  ref="modal"
  size="lg"
  v-model="packingModal"
  title="Packing Process"
  ok-only
  no-close-on-esc
  no-close-on-backdrop
  hide-header-close
>
  <b-row>
    <b-col cols="3">
      SO Number :
    </b-col>
    <b-col cols="9">
      {{ this.form.item[0].pickList.orderNumber }}
    </b-col>
    <b-col cols="3">
      PickList Number :
    </b-col>
    <b-col cols="9">
      {{ this.form.item[0].pickList.picklistNo + "_" + this.form.item[0].pickList.picklistLine }}
    </b-col>
    <b-col cols="3">
      Created At:
    </b-col>
    <b-col cols="9">
      {{ this.form.item[0].pickList.createdAt }}
    </b-col>
    <b-col cols="3">
      Updated At :
    </b-col>
    <b-col cols="9">
      {{ this.form.item[0].pickList.updatedAt }}
    </b-col>
    <b-col cols="3">
      Updated At :
    </b-col>
    <b-col cols="9">
      {{ this.form.item[0].pickList.updatedAt }}
    </b-col>
    <b-col cols="3">
      Timer:
    </b-col>
    <b-col cols="9">
      {{ (this.startTimer==null)?"":totalTimer }}
    </b-col>
    <b-col cols="12" style="margin-top:10px">
      <b-row>
        <b-col cols="6">
            <b-button
            variant="success"
            size="sm"
            block
            class="float-right"
            :disabled="disableStart"
            @click="
                startPacking()
              "
              >Start Packing</b-button
            >
        </b-col>
          <b-col cols="6">
              <b-button
              variant="danger"
              size="sm"
              block
              class="float-left"
                :disabled="disableEnd"
                @click="
                  endPacking()
                "
                >End Packing</b-button
              >
          </b-col>
      </b-row>
    </b-col>
  </b-row>
  <template #modal-footer>
    <div class="w-100"></div>
  </template>
</b-modal>
<!-- modal for idle time out -->
    <b-modal
      v-model="afkmodal"
      id="modal-prevent-closing"
      ref="modal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header="{}">
        <!-- Emulate built in modal header close button action -->
        <h5>Pause Job</h5>
      </template>

      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Select a Reason:"
          label-for="reason-select"
          invalid-feedback="Please select a reason"
          :state="reasonState"
        >
          <b-form-select
            id="reason-select"
            v-model="pauseReasonDefault"
            :options="pauseReasonOptions"
            :state="reasonState"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select a your Pause Reason --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </form>
      <template #modal-footer="">
        <b-button @click="restartAfkModal">save</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "@/axios";
import { mapGetters, mapActions } from "vuex";
import { dateFormat, userAccess, viewAccess, sleep } from "@/utils/utils.js";
export default {
  props: ["id"],
  data() {
    return {
      quantity: [],
      index: 0,
      promptDrum: false,
      title: "",
      fieldsTable: [
        { key: "pickList.fromBatch", label: "Drum Name" },
        { key: "quantity", label: "Quantity" },
        { key: "orderNumber", label: "Pick List" },
      ],
      editingItem: null,
      editingIndex: null,
      form: {},
      initialQuantities: [],
      dataJo:"",
      dataqty:[],
      originalData: {},
      disableStart: false,
      disableEnd: true,
      packingModal: false,
      startTimer:null,
      totalTimer:"",
      modalToLogout: 0,
      confirmationModal: false,
      //Pause
      afkmodal: false,
      pauseReasonDefault: null,
      pauseReasonOptions: [],
      reasonState: null,
      pauseIntervalID: null,
      logoutIntervalID: null,
      IDLE_TIMEOUT: 0,
      idleSecondsCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      jobOrders: "joborder/getJobOrder",
    }),
    pauseSetting() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      return pauseSetting[0];
    },
    getPauseJob() {
      let pauseSetting = this.$store.getters["pause/getPauseSetting"];
      this.IDLE_TIMEOUT = pauseSetting[0]?.intervalTime * 60;
      var user = this.$store.getters["auth/getActiveUser"];
      let pausejobs = this.$store.getters["pause/getPauseJobs"];
      var currpause = pausejobs.filter(
        (x) => x.user?.username == user.username || x.user?.email == user.email
      );
      var hasIdleTrue = currpause.filter((data) => data.props.idleRun == true);
      if (hasIdleTrue.length == 1) {
        this.idleSecondsCounter = this.IDLE_TIMEOUT - 1;
        return hasIdleTrue[0];
      } else {
        return currpause[0];
      }
    },
    getUser() {
      var user = this.$store.getters["auth/getActiveUser"];
      return { username: user.username, email: user.email };
    },
    items() {
      return this.$store.getters["bom/getComponents"];
    },
    userInfo() {
      const user = this.$store.getters["auth/getActiveUser"]
      return { username: user.username, email: user.email}
    },
  },
  methods: {
    ...mapActions({
      getJobOrder: "joborder/getJobOrder",
    }),
    async timerRunStart(){
        var currentTime = new Date().getTime();
        var startTime = new Date(this.startTimer).getTime();
        var elapsedTime = currentTime - startTime;

        const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
      const minutes = Math.floor(
        (elapsedTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

      this.totalTimer= `${hours
        .toString()
        .padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        await sleep(1000)
        if(this.startTimer!=null){
          this.timerRunStart()
        }
    },
    toPacking(item){

      // return this.packingModal = true;

      this.dataqty = item

      this.dataqty.forEach(el => {
        this.form.item.forEach(ele => {
          if (el.quantity == ele.quantity ) {
          var data = this.form.item.map(el => {
          return el.pickList.id
        })
            
        for (let i = 0; i < data.length; i++)
        {
          var pickList = this.$store.getters["picklist/getPicklist"].filter(ef =>{
            return ef.id === data[i]
          }).map(el => {
            return {
              ...el,
              props:{
                app: el.props.app,
                status: el.props.status,
                statusNo: 35,
                statusCode: "PLE",
                cable_length: Number(this.dataqty[i].quantity),
                flag: "To Packing"
              },
              quantity: this.dataqty[i].quantity
            }
          })
                
          this.$store.dispatch("picklist/updatePicklist", {
            id: data[i],
            data: pickList[i]
          })
            .then(() => {
              this.packingModal = true;
              this.$bvToast.toast("Successfully sent to Packing", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch(err => {
              this.$bvToast.toast(err.message, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
            }
          }
          console.log('this.packingModal', this.packingModal);
          if (el.quantity != ele.quantity) {
            this.confirmationModal = true;
          }
        })
      })
    },
    async confirm() {

      // return this.packingModal = true;

      var data = this.form.dataItem.map(el => {
        return el.pickList.id
      })

      for (let i = 0; i < data.length; i++)
      {
        var pickList = this.$store.getters["picklist/getPicklist"].filter(ef =>{
          return ef.id === data[i]
        }).map(el => {
          return {
            ...el,
            props:{
              ...el.props,
              statusNo: 35,
              statusCode: "PLE",
              cable_length: Number(this.dataqty[i].quantity)
            },
            quantity: this.dataqty[i].quantity
          }
        })
  
        await this.$store.dispatch("picklist/updatePicklist", {
          id: data[i],
          data: pickList[i]
        })
          .then(() => {
            this.$bvToast.toast("Successfully sent to Packing", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.confirmationModal = false;
            this.packingModal = true;
          })
          .catch(err => {
            this.$bvToast.toast(err.message, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });

          var jobOrderIndex = this.dataJo.item.findIndex(item => item.orderNumber === this.form.dataItem[i].orderNumber);

          var updateItem = this.dataJo.item
          updateItem[jobOrderIndex].quantity = this.dataqty[i].quantity
          updateItem[jobOrderIndex].pickList = updateItem[jobOrderIndex].pickList.id

          this.dataJo.item = updateItem

          var sendData = {
            ...this.dataJo
          }

          await this.$store.dispatch("joborder/updateJobOrder", {
            id: this.dataJo.id,
            data: sendData,
          })
          .then(() => {
            this.autoFillDataTable()
          })
          .catch(err => {
            this.$bvToast.toast(err.message, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }

        
    },
    reset() {
      // Reset all quantities to their initial values
      this.form.dataItem.forEach((item, index) => {
        item.quantity = this.initialQuantities[index];
      });
    
      // Close the confirmation modal if it's open
      this.confirmationModal = false;
    },
    async startPacking() {
      var dataPicklist = this.form.dataItem[0].pickList

      let sendStatus = {
        ...dataPicklist,
        props:{
            ...dataPicklist.props,
            app: dataPicklist.props.app,
            status: dataPicklist.props.status,
            statusNo: 50,
            flag: 'Start Packing'
        },
        quantity: dataPicklist.props.cable_length
      };

      await this.$store
        .dispatch("picklist/updatePicklist", {
          id: dataPicklist.id,
          data: sendStatus,
        })
        .then(() => {
          this.$bvToast.toast("Successfully change status", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
        this.$store
        .dispatch("picklist/getPicklist")
        .catch((err) => console.log(err));
        
        await this.$store.dispatch("picklist/pickListTimestamp",{id: dataPicklist.id,timestamp:"PS"}).
        then(x=>{
          this.stopPauseInterval()
          this.startTimer=new Date();
          this.timerRunStart();
          this.$bvToast.toast("Picklist Start Packing", {
              title: "Success",
              variant: "success",
              solid: true,
            });
        }).
        catch(err=>{
          this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
        })  
      
      this.disableStart = true
      this.disableEnd = false


    },
    async endPacking() {
      var dataPicklist = this.form.dataItem[0].pickList

      this.startTimer=null
      this.startPauseInterval()
      let sendStatus = {
        ...dataPicklist,
        props:{
            ...dataPicklist.props,
            statusNo: 55,
            PE: new Date()
        },
        quantity: dataPicklist.props.cable_length
      };

      await this.$store
        .dispatch("picklist/updatePicklist", {
          id: dataPicklist.id,
          data: sendStatus,
        })
        .then(() => {
        this.$store.dispatch("picklist/getPicklist");
          this.$bvToast.toast("Successfully change status", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });
      this.$store
        .dispatch("picklist/getPicklist")
        .catch((err) => console.log(err));

        await this.$store.dispatch("picklist/pickListTimestamp",{id: dataPicklist.id,timestamp:"PE"}).
          then(x=>{
            this.$bvToast.toast("Picklist Completed Packing", {
                title: "Success",
                variant: "success",
                solid: true,
              });
          }).
          catch(err=>{
            this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
          })
        this.jobEnd()
        this.packingModal = false;
    },
    restartAfkModal() {
      this.loading = true
      if (!this.checkFormValidity()) {
        return;
      }
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
      this.startPauseInterval()

      if (this.pauseReasonDefault != null) {
        var pauseData = {
          user: this.userInfo,
          pauseReason: this.pauseReasonDefault,
          pauseReference: "Cutter HMI List "
        };
        this.$store
          .dispatch("pause/savePauseJob", pauseData )
          .then((res) => {
            this.loading = false
            console.log("puase saved", res, pauseData);
            this.afkmodal = false;
            this.stopLogoutInterval();
            this.$bvToast.toast("PauseJob Updated", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch((err) => {
            this.loading = false
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "warning",
              solid: true,
            });
          });
      }
    },
    checkIdleTime() {
      this.idleSecondsCounter++;
      console.log(this.IDLE_TIMEOUT,":",this.idleSecondsCounter);
      if (this.idleSecondsCounter === this.IDLE_TIMEOUT) {
        
        var pauseData = {
          user: this.userInfo,
          pauseTimestamp: new Date(),
          props: {
            idleRun: true,
          },
          pauseInterval: this.pauseSetting.intervalTime,
        };
          this.$store
            .dispatch("pause/addPauseJob", pauseData)
            .then((res) => {
              this.stopPauseInterval();
              this.startLogoutInterval()
              this.afkmodal = true;
              this.$bvToast.toast("PauseJob Added", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            })
            .catch((err) => {
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "warning",
                solid: true,
              });
            });
      }
    },
    /* 
      when idle timeout modal showed, this function will started
      and running an interval to check if current time as same as
      auto logout time from pause setting, if it happend system will auto logout 
    */
    idleToLogout() {
      var TimeSet = this.pauseSetting.logoutSessionMorning.split(":");
      var TimeSet2 = this.pauseSetting.logoutSessionNight.split(":");
      console.log(new Date().getHours(), ':',TimeSet[0], ' ', new Date().getMinutes(),':' ,TimeSet[1]);
      if (
        (new Date().getHours() == TimeSet[0] &&
          new Date().getMinutes() == TimeSet[1]) ||
        (new Date().getHours() == TimeSet2[0] &&
          new Date().getMinutes() == TimeSet2[1])
      ) {
        this.stopLogoutInterval();
        var pauseData = {
          user: this.userInfo,
          pauseReason: "Idle auto Logout",
          pauseReference: "Cutter HMI List"
        };
        this.$store
        .dispatch("pause/savePauseJob", pauseData )
        .then((res) => {
          this.$bvToast.toast("PauseJob Updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          //logout function
          axios.post("/common/auth/logout").then(() => {        
            this.$session.clear()
            this.$session.destroy()
            window.location.reload()
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
      }
    },
    startPauseInterval() {
      this.pauseIntervalID = window.setInterval(this.checkIdleTime, 1000);
      console.log("startPauseInterval init");
    },
    stopPauseInterval() {
      window.clearInterval(this.pauseIntervalID);
      console.log("stopPauseInterval init");
    },
    startLogoutInterval() {
      this.logoutIntervalID = window.setInterval(this.idleToLogout, 1000);
      console.log("startLogoutInterval init");
    },
    stopLogoutInterval() {
      window.clearInterval(this.logoutIntervalID);
      console.log("stopLogoutInterval init");
    },
    //Pause Functions - END
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.reasonState = valid;
      return valid;
    },
    async autoFillDataTable() {
      await axios
        .get("/manufacture/master-data/joborder/" + this.$props.id)
        .then((res) => {
          this.form = res.data.data
          this.dataJo = res.data.data
          console.log('form', this.form);

          let dataItem = this.form.item.map((el) => {
            // this.quantity.push(el.quantity)
            return {
              ...el
            };
          });

          this.initialQuantities = res.data.data.item.map(item => item.quantity);

          this.form = {
            ...res.data.data,
            dataItem: dataItem,
          };
        });
    },
    async jobEnd(){
       let itemOnlyId = this.dataJo.item.map(el=>{
        return {
          orderNumber: el.orderNumber,
          quantity: el.quantity,
          pickList: el.pickList.id
        }
      })

      var sendStatus = this.dataJo
      sendStatus.item = itemOnlyId
      sendStatus.status = "Finished"
      sendStatus.props.status = 60
      
      
      await this.$store
        .dispatch("joborder/updateJobOrder", {
          id: sendStatus.id,
          data: sendStatus,
        })
        .then(() => {
          this.$bvToast.toast("Successfully change status to 'Finished'", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        });

      this.$store
        .dispatch("joborder/jobOrderTimestamp", {
          id: sendStatus.id,
          timestamp: "JE",
        })
        .then((x) => {
          this.babyModal = false;
          this.$bvToast.toast("Job Ended", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "warning",
            solid: true,
          });
        });
        this.$router.push({ name: "direct-sales" });
    }
  },
  async mounted() {
    try {
      const res = await axios.get("/manufacture/master-data/joborder/" + this.$props.id);
      this.form = res.data.data;
      this.dataJo = res.data.data;
      if (this.form.item[0].pickList.props.flag == 'To Packing') {
        this.packingModal = true
      }
      if (this.form.item[0].pickList.props.flag == 'Start Packing') {
        this.disableStart = true
        this.disableEnd = false
        this.packingModal = true
      } 
      let dataItem = this.form.item.map((el) => {
        return {
          ...el
        };
      });

      this.initialQuantities = res.data.data.item.map(item => item.quantity);
      this.form = {
        ...res.data.data,
        dataItem: dataItem,
      };
      console.log('form', this.form);
      this.$store.dispatch("picklist/getPicklist");
      this.$store.dispatch("plant/getReasonList").then((res) => {
        var drumPickReason = this.$store.getters["plant/getReasonList"].filter(
          (x) => x.reasonName == "Pause Job"
        );
        var allreason = drumPickReason[0].reasonList;
        var activeReason = allreason.filter((x) => x.status === "Active")
        this.reasonListDrumPick = activeReason.map((x) => x.reason)
      });

      //idle time out modal mounted
      let user = this.$store.getters["auth/getActiveUser"]
        this.$store.dispatch("pause/getActivePauseJob", user).then((res)=> {
        /* 
        result "res.pause" will be true or false
        if database has document pausejob with current username and idleRun = true,
        modal will appeared
        */
        this.afkmodal = res.pause
        res.pause ? this.startLogoutInterval() : this.startPauseInterval()
      })
        this.$store.dispatch("pause/getPauseJobSetting").then((res) =>{
        this.IDLE_TIMEOUT = res[0].intervalTime * 60
      })
      document.onclick = () => {
        this.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        this.idleSecondsCounter = 0;
      };
      document.onkeydown = () => {
        this.idleSecondsCounter = 0;
      };
      document.onscroll = () => {
        this.idleSecondsCounter = 0;
      };
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  },
  watch: {
    afkmodal(value) {
      if(value) {
        // get reason list of pause job
        this.$store.dispatch("plant/getReasonList").then((res) => {
          var PauseJobReason = res.filter((x) => x.reasonName == "Pause Job");
          var allreason = PauseJobReason[0].reasonList;
          var activeReason = allreason.filter((x) => x.status === "Active")
          this.pauseReasonOptions = activeReason.map((x) => x.reason)
        });
      }
    },
  },
  destroyed() {
    this.stopPauseInterval();
    this.stopLogoutInterval();
  },
};
</script>
